﻿import { global } from '../Common/saop-common';
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopServicesCore } from '../Core/saop-services-core'
import { SaopMealApplicationListView } from "./saop-meal-application-list-view";
import { SaopMealApplicationBeneficiaryView } from "./saop-meal-application-beneficiary-view";
import { SaopMealApplicationFormView } from "./saop-meal-application-form-view";
import { SaopMealApplicationPreviewView } from "./saop-meal-application-preview-view"; 

export class SaopMealApplicationView {
    private _libName: string;

    //edit forma
    private _listView: SaopMealApplicationListView;
    private _formView: SaopMealApplicationFormView;
    private _previewView: SaopMealApplicationPreviewView;

    constructor(viewId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopMealApplicationView");
    }

    initView() {
        global.ConsoleLogDegug("SaopMealApplicationView.initView");

        let _this = this;
        _this.setTop();
        window.onresize = function () {
            _this.setTop();
        };

        this.goToList();
    }

    goToList(): void {
        global.ConsoleLogDegug("SaopMealApplicationView.goToList");

        this._listView = new SaopMealApplicationListView("#mealApplicationViewList", this._libName);
        this._listView.setTop = this.setTop.bind(this);
        this._listView.initView();
        this._listView.goToForm = this.goToForm.bind(this);
    }

    goToForm(sifraOtroka: string): void  {
        global.ConsoleLogDegug("SaopMealApplicationView.goToForm");

        this._formView = new SaopMealApplicationFormView("#mealApplicationViewForm", this._libName);
        this._formView.setTop = this.setTop.bind(this);
        this._formView.initView();
        this._formView.goToList = this.goToList.bind(this);
        this._formView.goToPreview = this.goToPreview.bind(this);
        this._formView.submitMealApplication = this.submitMealApplication.bind(this);
        this._formView.refreshForm(sifraOtroka);
    }

    goToPreview(idPrijave: bigint): void {
        global.ConsoleLogDegug("SaopMealApplicationView.goToPreview");

        this._previewView = new SaopMealApplicationPreviewView("#mealApplicationViewPreview", this._libName);
        this._previewView.setTop = this.setTop.bind(this);
        this._previewView.initView();
        this._previewView.goToList = this.goToList.bind(this);
        this._previewView.goToFilledForm = this.goToFilledForm.bind(this);
        this._previewView.submitMealApplication = this.submitMealApplication.bind(this);
        this._previewView.refreshPreview(idPrijave);
    }

    goToFilledForm(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationView.goToFilledForm");

        this._formView = new SaopMealApplicationFormView("#mealApplicationViewForm", this._libName);
        this._formView.setTop = this.setTop.bind(this);
        this._formView.initView();
        this._formView.submitMealApplication = this.submitMealApplication.bind(this);
        this._formView.goToList = this.goToList.bind(this);
        this._formView.goToPreview = this.goToPreview.bind(this);
        this._formView.refreshFilledForm(idPrijave);
    }

    setTop() {
        global.ConsoleLogDegug("SaopMealApplicationView.setTop");
        var _top = $('header').height();
        $('.application-container').css("top", _top);
    }

    //akcije
    submitMealApplication(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationView.submitMealApplication");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/DocumentSubmit.afterDone");
            _this.goToList();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/DocumentSubmit"
            , data: {
                IDPrijave: idPrijave
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }
}


