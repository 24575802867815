﻿import { global } from '../Common/saop-common';
import { SaopServicesCore } from '../Core/saop-services-core'

export class SaopMealApplicationFormView {
    private _libName: string;

    constructor(viewId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopMealApplicationFormView");

        this._libName = libName;
    }

    initView() {
        global.ConsoleLogDegug("SaopMealApplicationFormView.initView");

        let _this = this;
        window.onresize = function () {
            _this.setTop();
        };
    }

    initViewControls() {
        global.ConsoleLogDegug("SaopMealApplicationFormView.initViewControls");
        let _this = this;
        $('#btnNext').on('click', function (e) {
            _this.submitForm(false);
        });
        $('#btnSubmit').on('click', function (e) {
            _this.submitForm(true);
        });
        $('#btnCancel').on('click', function (e) {
            _this.goToList();
        });
    }

    refreshForm(sifraOtroka: string): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.refreshForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/Form.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.setTop();
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/Form"
            , data: {
                sifraOtroka: sifraOtroka
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    refreshFilledForm(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.refreshForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/Form.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.setTop();
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/FilledForm"
            , data: {
                idPrijave: idPrijave
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    submitForm(skipPrview: boolean): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.submitForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;
        let data = $('form').serialize(); // or use the id of the form is you have given it one

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/SubmitForm.afterDone");
            if (response.success) {
                let idPrijave = response.viewContent;
                if (skipPrview) {
                    _this.submitMealApplication(idPrijave);
                }
                else {
                    _this.goToPreview(idPrijave);
                }
            }
            else {
                $("#mealApplicationDiv").html(response.viewContent);
                _this.setTop();
                _this.initViewControls();
            }
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/FormSubmit"
            , data: data
            , type: "POST"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    goToPreview(idPrijave: bigint): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.goToPreview");
    }

    goToList(): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.goToList");
    }

    setTop() {
        global.ConsoleLogDegug("SaopMealApplicationFormView.setTop");
    }

    submitMealApplication(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.submitMealApplication");
    }
}


