﻿import { global } from '../Common/saop-common';
import { SaopServicesCore } from '../Core/saop-services-core'

export class SaopMealApplicationPreviewView {
    private _libName: string;

    constructor(viewId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView");

        this._libName = libName;
    }

    initView() {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.initView");

        let _this = this;
        window.onresize = function () {
            _this.setTop();
        };
    }

    initViewControls() {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.initViewControls");
        let _this = this;

        $('#btnSubmit').on('click', function (e) {
            let idPrijave = $("#IDPrijave").val().toString();
            _this.submitMealApplication(idPrijave);
        });
        $('#btnPrevious').on('click', function (e) {
            let idPrijave = $("#IDPrijave").val().toString();
            _this.goToFilledForm(idPrijave);
        });
        $('#btnCancel').on('click', function (e) {
            _this.goToList();
        });
    }

    refreshPreview(idPrijave: bigint): void {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.refreshForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationPreviewView.ExecuteAction.MealApplication/Preview.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.setTop();
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/Preview"
            , data: {
                IDPrijave: idPrijave
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    goToList(): void {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.goToList");
    }

    goToFilledForm(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.goToForm");
    }

    setTop() {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.setTop");
    }

    submitMealApplication(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationPreviewView.submitMealApplication");
    }
}


